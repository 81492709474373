<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2
            mt-5>
            <v-card class="wrap-content gray-icons">
                <v-card-title class="pt-4 px-4 pb-0">
                    <span class="title primary--text">New Agency</span>
                </v-card-title>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            ref="form"
                            v-model="valid">
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="name"
                                        :rules="nameRules"
                                        name="name"
                                        label="Agency Name"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="url"
                                        name="url"
                                        label="Homepage URL"
                                        prepend-icon="home"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        name="email"
                                        label="Leads From Email"
                                        prepend-icon="email"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="gaAccountId"
                                        name="ga_account_id"
                                        label="Google Analytics Account ID"
                                        prepend-icon="mdi-google"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="gtmAccountId"
                                        name="gtm_account_id"
                                        label="Google Tag Manager Account ID"
                                        dark
                                        prepend-icon="mdi-google"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="facebookBusinessId"
                                        name="facebook_business_id"
                                        label="Facebook Business ID"
                                        prepend-icon="mdi-facebook-box"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-btn
                                :disabled="!valid"
                                @click="submit()">
                                submit
                            </v-btn>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '../../http';

    export default {
        title: 'Add Agency',
        data() {
            return {
                valid: false,
                name: '',
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ],
                url: '',
                gaAccountId: '',
                gtmAccountId: '',
                facebookBusinessId: '',
                errors: [],
            };
        },
        methods: {
            submit() {
                (new HTTP)
                    .post(
                        '/agencies',
                        {
                            name: this.name,
                            utm_company_name: window.toKebabCase(this.name),
                            url: this.url,
                            leads_from_email: this.email,
                            ga_account_id: this.gaAccountId,
                            gtm_account_id: this.gtmAccountId,
                            facebook_business_id: this.facebookBusinessId,
                        }
                    )
                    .then(() => {
                        this.$flash('Data added successfully!', 'green');
                        this.clearForm();
                    })
                    .catch(e => {
                        this.$flash('Error adding new agency data!', 'red');
                        console.log(e);
                    });
            },
            clearForm() {
                this.$refs.form.reset();
            }
        }
    };
</script>
